.paper{
  position: relative;
  height: 190px;
  width: 330px;
  text-align: start;
  padding: 24px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  cursor: pointer;
  transition: all 0.2s 0s ease;
  overflow: hidden;
  margin-bottom: 24px;
  &:hover{
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      .hoverMenu{
          bottom: 0;
      }
  }
}

.gridItem{
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.title{
  font-size: 18px;
  font-weight: bold;
}

.hoverMenu{
  position: absolute;
  bottom: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0b3a8a;
  transition: all 0.2s 0s ease;
}

.hoverButtons{
  position: absolute;
  bottom: 32px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.centerItem{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}