.sideBarWrapper {
  width: 250px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-right: 2px solid #d9d9d9;
}

.logoWrapper{
  margin: 44px auto 0 auto; 
  width: 70%
}

.navigationWrapper{
  width: 70%;
  margin: 44px auto 0 auto;
}

.navigationItem{
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-bottom: 42px;
}

.navigationText{
  font-size: 18px;
  margin-left: 14px;
}

.rightElement{
  height: 40px;
  width: 7px;
  background-color: #0b3a8a;
  position: absolute;
  right: 0;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.bottomItems{
  position: absolute;
  bottom: 30px;
}