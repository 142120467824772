//NEEDED ???
.classicButton {
    background-color: white;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    appearance: none;
    font-size: 1.3rem;
    // box-shadow: 0px 8px 28px -6px rgba(24, 39, 75, 0.12),
    //     0px 18px 88px -4px rgba(24, 39, 75, 0.14);
    transition: all ease-in 0.1s;
    cursor: pointer;
    opacity: 0.9;
    border: 1px solid black;

    &:hover {
        transform: scale(1.05);
        opacity: 1;
    }
}

.cancleButton {
    border-color: red !important;
    color: red !important;
}

.continueButton {
    background-color: #0b3a8a !important;
    color: white;
}