.wrapper {
    // width: 70%;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
}

.fileUploader {
    width: 400px;
    height: 400px !important;
    background-image: url("../../../public/common/illustrations/upload.svg") !important;
    background-color: #0b3a8a17 !important;
    background-size: 60%;
    background-repeat: no-repeat;
    background-position: center 60px;
    border-color: #0b3a8a !important;
    > div {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
        > span {
            font-size: 18px;
            > span {
                color: #0b3a8a;
            }
        }
        > span:nth-child(2) {
            display: none !important;
        }
    }
    > svg {
        display: none;
    }
}

.selectedFilesWrapper{
    width: 350px;
    margin-left: 24px;
    padding-top: 16px;
}

.selectedFile{
    width: 100%;
    display: flex;
    height: fit-content;
    align-items: center;
}

.iconRight{
    position: absolute;
    right: 0;
}

.buttonsWrapper{
    position: absolute;
    bottom: -60px;
}