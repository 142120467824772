.outerWrapper {
  width: 100vw;
  height: 100vh;
  background-color: #0b3a8a;
  background: linear-gradient(221deg, rgba(27, 179, 235, 1) 0%, rgba(37, 239, 203, 1) 100%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loginPaper {
  width: 50%;
  height: 60%;
  border-radius: 16px !important;
}

.centerChildren {
  display: flex;
  justify-content: column;
  align-items: center;
  justify-content: center;
}

.imageContainer {
  background-image: url("../../../public/loginPage/hero.jpg");
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: 20%;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
}

.innerLoginWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.inputFields {
  width: 70%;
}