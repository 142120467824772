.searchText{
    margin-bottom: 12px;
    font-size: 28px;
}


.textFieldset > div {
    > input {
        width: 600px;
        border-color: #0b3a8a;
    }

    > fieldset{
        border-radius: 60px;
        border-color: #0b3a8a !important;
        border-width: 3px;
    }
}